<template>
  <v-container fluid class="px-4">
    <v-row>
      <v-col>
        <div>
          <h1 class="ml-2 mt-2" style="font-weight: 200">
            {{ processedSingleCampaign.processedName }}
          </h1>
        </div>
        <div class="d-flex">
          <v-btn
            x-small
            class="grey--text ml-2"
            elevation="0"
            @click="() => this.$router.push({ path: '/abstract-campaign' })"
            >Todas las Campañas</v-btn
          >
          <v-btn
            x-small
            class="grey--text ml-2"
            elevation="0"
            @click="
              () =>
                this.$router.push({
                  path: '/abstract-campaign/' + this.$route.params.id1,
                })
            "
            >Ir a versiones de Campañas</v-btn
          >
          <v-btn
            x-small
            class="grey--text ml-2"
            elevation="0"
            @click="
              () =>
                this.$router.push({
                  path: this.$route.fullPath + '/modifiers',
                })
            "
            >Ir a Simulador</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex mt-2">
          <v-btn
            text
            elevation="0"
            :color="showEngagements ? 'primary' : 'grey'"
            @click="showCampaignSection('showEngagements')"
          >
            Engagements
          </v-btn>

          <v-btn
            text
            class="ml-2"
            elevation="0"
            :color="showMetrics ? 'primary' : 'grey'"
            @click="showCampaignSection('showMetrics')"
          >
            Metricas
          </v-btn>
          <v-btn
            text
            class="ml-2"
            elevation="0"
            :color="showNewEngagement ? 'primary' : 'grey'"
            @click="showCampaignSection('showNewEngagement')"
          >
            Nuevo Engagement
          </v-btn>
          <v-btn
            text
            class="ml-2"
            elevation="0"
            :color="showCashflows ? 'primary' : 'grey'"
            @click="showCampaignSection('showCashflows')"
          >
            Cashflows
          </v-btn>
          <v-btn
            text
            class="ml-2"
            elevation="0"
            :color="showActions ? 'primary' : 'grey'"
            @click="showCampaignSection('showActions')"
          >
            Acciones
          </v-btn>
          <v-btn
            text
            class="ml-2"
            elevation="0"
            :color="showPrices ? 'primary' : 'grey'"
            @click="showCampaignSection('showPrices')"
          >
            Precios
          </v-btn>
          <v-btn
            text
            class="ml-2"
            elevation="0"
            :color="showCampaignDetail ? 'primary' : 'grey'"
            @click="showCampaignSection('showCampaignDetail')"
          >
            Globals
          </v-btn>
          <v-btn
            text
            class="ml-2"
            elevation="0"
            :color="showCampaignHistory ? 'primary' : 'grey'"
            @click="
              showCampaignSection('showCampaignHistory');
              refreshLogs();
            "
          >
            Ultimas Modificaciones
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showEngagements && !isPageLoading" class="d-flex mt-0 pa-0">
      <v-col lg="8" sm="12" md="8" class="ma-0">
        <div class="d-flex align-center">
          <v-chip
            class="mx-2"
            color="info"
            :disabled="selectedRegions.length == availableRegions.length"
            @click="selectedRegions = availableRegions"
          >
            All
          </v-chip>
          <v-chip
            class="mx-2"
            color="red white--text"
            :disabled="selectedRegions.length == 0"
            @click="selectedRegions = []"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-chip>
          <v-chip-group v-model="selectedRegions" column multiple>
            <v-chip
              filter
              variant="outlined"
              class="mx-2"
              v-for="region in availableRegions"
              :key="region"
              :value="region"
              color="primary"
              >{{ region }}</v-chip
            >
          </v-chip-group>
        </div>
      </v-col>
      <v-col lg="4" sm="12" md="4" class="ma-0">
        <div class="">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showEngagements && campaignEngagementsValues.length">
      <v-col
        lg="4"
        sm="12"
        md="4"
        v-for="campaignEngagement in selectableCampaignEngagementsValues"
        :key="campaignEngagement.engagementId"
      >
        <v-card
          elevation="0"
          color="grey lighten-3"
          @click="
            (e) =>
              $router.push(`/engagement/${campaignEngagement.engagementId}`)
          "
        >
          <v-card-text style="font-size: 22px">
            <div class="d-flex justify-space-between ml-2 black--text mb-2">
              <span>
                {{ campaignEngagement.establishmentName }}
              </span>
              <span class="grey--text">
                {{ campaignEngagement.region }}
              </span>
            </div>

            <div class="ml-2 black--text">
              {{ campaignEngagement.totalHectares }} Ha.
              <span class="grey--text"> Totales </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-else-if="
        showEngagements &&
        !campaignEngagementsValues.length &&
        !search &&
        !isPageLoading
      "
    >
      <v-col>
        <v-col>
          <div class="d-flex justify-space-center">
            <p class="button text-center text--disabled pa-5 text--lighten-5">
              Todavia no hay engagements en esta campaña
            </p>
          </div>
        </v-col>
      </v-col></v-row
    >
    <v-row
      v-else-if="showEngagements && !campaignEngagementsValues.length && search"
    >
      <v-col>
        <v-col>
          <div class="d-flex justify-space-center">
            <p class="button text-center text--disabled pa-5 text--lighten-5">
              No hay coincidencias para la busqueda
            </p>
          </div>
        </v-col>
      </v-col></v-row
    >
    <v-row v-if="showMetrics">
      <v-col cols="12">
        <div>
          <v-card flat class="pa-4 grey lighten-4">
            <div class="d-flex flex-wrap align-center">
              <v-select
                label="Region"
                :items="availableRegionOptions"
                item-text="abbreviation"
                item-value="id"
                v-model="regionIdFilter"
                dense
                outlined
                clearable
                hide-details
                background-color="white"
                class="filter-field mr-4"
                style="width: 200px"
                prepend-inner-icon="mdi-map-marker"
              ></v-select>

              <v-select
                label="Especie"
                :items="availableSpeciesOptions"
                item-text="name"
                item-value="id"
                v-model="speciesIdFilter"
                dense
                outlined
                clearable
                hide-details
                background-color="white"
                class="filter-field mr-4"
                style="width: 200px"
                :disabled="!!activityIdsFilter.length"
                prepend-inner-icon="mdi-sprout"
              ></v-select>

              <v-select
                label="Actividades"
                :items="availableActivityOptions"
                item-text="name"
                item-value="id"
                v-model="activityIdsFilter"
                dense
                outlined
                clearable
                hide-details
                background-color="white"
                class="filter-field mr-4"
                style="width: 300px"
                multiple
                :menu-props="{ closeOnContentClick: true }"
                :disabled="!!speciesIdFilter"
                prepend-inner-icon="mdi-tractor"
              >
                <template v-slot:selection="{ index }">
                  <span v-if="index === 0">
                    {{ activityIdsFilter.length }} actividades seleccionadas
                  </span>
                </template>
              </v-select>

              <v-btn
                small
                color="warning"
                class="ml-auto"
                elevation="0"
                :disabled="isCashflowFiltered"
                @click="
                  getCampaignCashflowFiltered();
                  getCampaignMetricsFiltered();
                "
              >
                <span class="mr-2">Aplicar Filtros</span>
                <v-icon>mdi-filter-check-outline</v-icon>
              </v-btn>
            </div>

            <div class="mt-2 d-flex align-center" v-if="hasActiveFilters">
              <span class="grey--text text--darken-1 text-caption mr-2"
                >Filtros activos:</span
              >
              <v-chip
                small
                class="mr-2"
                close
                @click:close="regionIdFilter = null"
                v-if="regionIdFilter"
              >
                Región: {{ getRegionName(regionIdFilter) }}
              </v-chip>
              <v-chip
                small
                class="mr-2"
                close
                @click:close="speciesIdFilter = null"
                v-if="speciesIdFilter"
              >
                Especie: {{ getSpeciesName(speciesIdFilter) }}
              </v-chip>
              <template v-if="activityIdsFilter.length">
                <v-chip
                  small
                  class="mr-2"
                  v-for="id in activityIdsFilter"
                  :key="id"
                  close
                  @click:close="removeActivity(id)"
                >
                  {{ getActivityName(id) }}
                </v-chip>
              </template>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col lg="12" md="12" sm="12" class="mb-2">
        <v-divider></v-divider>
        <InfoGrid
          class="my-2"
          :infos="campaignKpi"
          infoTitle="KPIs"
          infoTitleClass="grey--text h2"
          valueClass="display-2"
        />

        <v-divider></v-divider>
      </v-col>
      <v-col lg="6" md="6" sm="12" v-if="showT1InfoGrid">
        <InfoGrid
          class="my-2"
          :infos="t1MetricsInfoGrid"
          infoTitle="Resultados Estimados Totales"
          :columns="5"
          infoTitleClass="grey--text h2"
          valueClass="display-1"
          :buttonTag="changeUnitsButtonTagT1"
          @buttonClick="setMetricUnits('t1')"
          :buttonShow="true"
          @buttonShowClick="showT1InfoGrid = !showT1InfoGrid"
        />
      </v-col>
      <v-col lg="6" md="6" sm="12" v-if="!showT1InfoGrid">
        <AbstractDraftMetricDataTable
          :title="'Resultados Estimados Totales'"
          :hideFooter="true"
          :items="t1Metrics"
          :buttonTag="changeUnitsButtonTagT1"
          :headers="tHeaders.t1Headers"
          @buttonClick="setMetricUnits('t1')"
          :buttonShow="true"
          @buttonShowClick="showT1InfoGrid = !showT1InfoGrid"
        />
      </v-col>
      <v-col lg="6" md="6" sm="12" v-if="showT2InfoGrid">
        <InfoGrid
          class="my-2"
          :infos="t2Values"
          :subTitles="t2SubTitles"
          infoTitle="Rinde y Precio Por Cultivo"
          infoTitleClass="grey--text h2"
          valueClass="display-1"
          :buttonShow="true"
          @buttonShowClick="showT2InfoGrid = !showT2InfoGrid"
        />
        <div class="ms-3 mt-5" v-if="t2Values.length == 0">
          <span
            class="text-overline grey--text"
            style="font-size: 15px !important"
          >
            No hay actividades para mostrar
          </span>
        </div>
      </v-col>
      <v-col lg="6" md="6" sm="12" v-if="!showT2InfoGrid">
        <AbstractDraftMetricDataTable
          :title="'Rinde y Precio Por Cultivo'"
          :hideFooter="true"
          :items="t2Metrics"
          :headers="tHeaders.t2Headers"
          :groupBy="'activityName'"
          :orderBy="'activityName'"
          :hasSearch="true"
          :grupByStatus="makeActivityGroup"
          @ungroup="makeActivityGroup = !makeActivityGroup"
          :buttonShow="true"
          @buttonShowClick="showT2InfoGrid = !showT2InfoGrid"
        />
      </v-col>
      <v-col lg="12" md="12" sm="12" v-if="showT3InfoGrid">
        <InfoGrid
          class="my-2"
          :infos="t3MetricsInfoGrid"
          :subTitles="t3SubTitles"
          infoTitle="Rentabilidad Por Cultivo"
          infoTitleClass="grey--text h2"
          valueClass="display-1"
          :buttonTag="changeUnitsButtonTagT3"
          @buttonClick="setMetricUnits('t3')"
          :buttonShow="true"
          @buttonShowClick="showT3InfoGrid = !showT3InfoGrid"
        />
        <div class="ms-3 mt-5" v-if="t3Metrics.length == 0">
          <span
            class="text-overline grey--text"
            style="font-size: 15px !important"
          >
            No hay actividades para mostrar
          </span>
        </div>
      </v-col>
      <v-col lg="12" md="12" sm="12" v-if="!showT3InfoGrid">
        <AbstractDraftMetricDataTable
          :title="'Rentabilidad Por Cultivo'"
          :buttonTag="changeUnitsButtonTagT3"
          :hideFooter="true"
          :items="t3Metrics"
          :headers="tHeaders.t3Headers"
          :headersWidth="124"
          @buttonClick="setMetricUnits('t3')"
          :buttonShow="true"
          @buttonShowClick="showT3InfoGrid = !showT3InfoGrid"
        />
      </v-col>

      <v-col cols="12">
        <v-divider class="my-5"></v-divider>
      </v-col>
      <v-col class="">
        <AbstractDraftMetricDataTable
          :title="'Breakdown de Costos'"
          :hideFooter="true"
          :items="t4Metrics"
          :headers="tHeaders.t4Headers"
        />
      </v-col>
      <v-col lg="6" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Resumen Arrendamientos'"
          :hideFooter="true"
          :items="t5Metrics"
          :headers="tHeaders.t5Headers"
        />
      </v-col>
      <v-col>
        <AbstractDraftMetricDataTable
          :title="'Resumen Arrendamientos qq & usd'"
          :hideFooter="true"
          :items="t7Metrics"
          :headers="tHeaders.t7Headers"
        />
      </v-col>
      <v-col lg="6" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Aparcerias Por Cultivo'"
          :hideFooter="true"
          :items="t6Metrics"
          :headers="tHeaders.t6Headers"
        />
        <AbstractDraftMetricDataTable
          class="mt-2"
          :title="'Porcentajes Realizados Por Cultivo'"
          :hideFooter="true"
          :items="t8Metrics"
          :headers="tHeaders.t8Headers"
        />
      </v-col>
    </v-row>
    <v-row v-if="showMetrics && isCampaignActivitiesEmpty">
      <v-col class="d-flex justify-center pt-6 mt-10">
        <p class="button Button text--secondary text--lighten-5">
          Todavia no se encuentra cargada ninguna actividad a esta Campaña
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showNewEngagement">
      <AbstractCampaignNewEngagement
        :campaignId="campaignId"
        :establishments="filteredEstablishment"
        @saveNewEngagement="saveNewEngagement"
      />
    </v-row>

    <v-row v-if="showCampaignDetail">
      <AbstractCampaignDetails
        :processedSingleCampaign="processedSingleCampaign"
        @putAbstractCampaign="putAbstractCampaign"
        @putCampaign="putCampaign"
      />
    </v-row>
    <v-row v-if="showCampaignHistory">
      <v-col>
        <v-data-table
          style="overflow-x: hidden !important; max-width: 100%"
          :loading="logsLoading"
          :items="parsedLogs"
          :headers="[
            {
              text: 'Acción',
              value: 'logAction',
              class: 'primary white--text',
            },
            {
              text: 'Categoria',
              value: 'logResourceName',
              class: 'primary white--text',
            },
            {
              text: 'Actividad',
              value: 'logActivityName',
              class: 'primary        white--text',
            },
            {
              text: 'Negocio',
              value: 'logEngagementName',
              class: 'primary white--text',
            },
            {
              text: 'Fecha Ultima Modificación',
              value: 'logDate',
              class: 'primary white--text',
            },
            {
              text: 'Hora Ultima Modificación',
              value: 'logTime',
              class: 'primary white--text',
            },
            {
              text: 'Usuario',
              value: 'logActionUsername',
              class: 'primary white--text',
            },
          ]"
          hide-default-footer
          class="mt-2"
        >
          <template v-slot:[`item.logAction`]="{ item }">
            <span
              class="font-weight-medium"
              :class="{
                'red--text': item.logAction == 'Eliminado',
                'green--text': item.logAction == 'Nuevo',
                'yellow--text text--darken-2': item.logAction == 'Editado',
              }"
              >{{ item.logAction }}</span
            >
          </template>
          <template v-slot:[`item.logActivityName`]="{ item }">
            <span
              v-if="item.logActivityName == 'La actividad ya no existe.'"
              class="font-weight-medium"
              :class="{
                'red--text':
                  item.logActivityName == 'La actividad ya no existe.',
              }"
              >{{ item.logActivityName }}</span
            >
            <router-link
              v-else
              :to="{
                name: 'SingleActivitySlice',
                params: { id: item.logActivitySliceId },
              }"
              class="ma-0 pa-0 high-light-on-focus grey--text font-weight-bold"
            >
              {{ item.logActivityName }}
            </router-link>
          </template>
          <template v-slot:[`item.logEngagementName`]="{ item }">
            <span
              v-if="item.logEngagementName == 'El Negocio ya no existe.'"
              class="font-weight-medium"
              :class="{
                'red--text':
                  item.logEngagementName == 'El Negocio ya no existe.',
              }"
              >{{ item.logEngagementName }}</span
            >
            <router-link
              v-else
              :to="{
                name: 'SingleEngagement',
                params: { id: item.logEngagementId },
              }"
              class="ma-0 pa-0 high-light-on-focus grey--text font-weight-bold"
            >
              {{ item.logEngagementName }}
            </router-link>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            @input="getPage"
            v-model="page"
            :length="totalPages"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showCashflows">
      <v-col cols="12">
        <div>
          <v-card flat class="pa-4 grey lighten-4">
            <div class="d-flex flex-wrap align-center">
              <v-select
                label="Region"
                :items="availableRegionOptions"
                item-text="abbreviation"
                item-value="id"
                v-model="regionIdFilter"
                dense
                outlined
                clearable
                hide-details
                background-color="white"
                class="filter-field mr-4"
                style="width: 200px"
                prepend-inner-icon="mdi-map-marker"
              ></v-select>
              <v-select
                label="Especie"
                :items="availableSpeciesOptions"
                item-text="name"
                item-value="id"
                v-model="speciesIdFilter"
                dense
                outlined
                clearable
                hide-details
                background-color="white"
                class="filter-field mr-4"
                style="width: 200px"
                :disabled="!!activityIdsFilter.length"
                prepend-inner-icon="mdi-sprout"
              ></v-select>
              <v-select
                label="Actividades"
                :items="availableActivityOptions"
                item-text="name"
                item-value="id"
                v-model="activityIdsFilter"
                dense
                outlined
                clearable
                hide-details
                background-color="white"
                class="filter-field mr-4"
                style="width: 300px"
                multiple
                :menu-props="{ closeOnContentClick: true }"
                :disabled="!!speciesIdFilter"
                prepend-inner-icon="mdi-tractor"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ activityIdsFilter.length }} actividades seleccionadas
                  </span>
                </template>
              </v-select>
              <v-btn
                small
                color="warning"
                class="ml-auto"
                elevation="0"
                :disabled="isCashflowFiltered"
                @click="
                  getCampaignCashflowFiltered();
                  getCampaignMetricsFiltered();
                "
              >
                <span class="mr-2">Aplicar Filtros</span>
                <v-icon>mdi-filter-check-outline</v-icon>
              </v-btn>
            </div>

            <div class="mt-2 d-flex align-center" v-if="hasActiveFilters">
              <span class="grey--text text--darken-1 text-caption mr-2"
                >Filtros activos:</span
              >
              <v-chip
                small
                class="mr-2"
                close
                @click:close="regionIdFilter = null"
                v-if="regionIdFilter"
              >
                Región: {{ getRegionName(regionIdFilter) }}
              </v-chip>
              <v-chip
                small
                class="mr-2"
                close
                @click:close="speciesIdFilter = null"
                v-if="speciesIdFilter"
              >
                Especie: {{ getSpeciesName(speciesIdFilter) }}
              </v-chip>
              <template v-if="activityIdsFilter.length">
                <v-chip
                  small
                  class="mr-2"
                  v-for="id in activityIdsFilter"
                  :key="id"
                  close
                  @click:close="removeActivity(id)"
                >
                  {{ getActivityName(id) }}
                </v-chip>
              </template>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col>
        <DenseCashflowTable
          :rows="campaingCashflowRows"
          :totalHaToolTip="campaignTotalHaToolTip"
          :columns="campaignCashflowColumns"
          :isResourceUtilization="false"
          :startDate="campaignStartDate"
          :endDate="campaignEndDate"
        />
      </v-col>
      <v-col cols="12">
        <v-divider class="mt-2 mb-2"></v-divider>
        <div :class="['resource-utilization-header', { 'is-muted': !shouldShowResourceUtilization || !hasResourceUtilizationData }]">
          <h2 class="ml-4">Utilizacion de Recursos</h2>

          <v-select
            v-model="resourceUtilizationFilter"
            :items="resourceUtilizationFilterItems"
            label="Filtrar por estado"
            outlined
            dense
            class="mx-4 mt-2"
            style="max-width: 300px"
            @change="handleResourceUtilizationFilterChange"
            :disabled="!shouldShowResourceUtilization || !hasResourceUtilizationData"
          ></v-select>
        </div>

        <div ref="resourceUtilizationSection">
          <div
            v-if="!shouldShowResourceUtilization || !hasResourceUtilizationData"
            class="d-flex flex-row justify-center"
          >
            <span class="grey--text text-overline pa-5 ma-5">
              {{ resourceUtilizationLoadingMessage }}
            </span>
          </div>
          <div 
            v-else
            class="resource-utilization-content"
          >
            <DenseCashflowTable
              name="Tabla de insumos"
              :rows="campaignResourceUtilizationRows"
              :columns="campaingResourceUtilizationColumns"
              :isResourceUtilization="true"
              :startDate="campaignStartDate"
              :endDate="campaignEndDate"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showCashflows && isCampaignActivitiesEmpty">
      <v-col class="d-flex justify-center pt-6 mt-10">
        <p class="button Button text--secondary text--lighten-5">
          Todavia no se encuentra cargada ninguna actividad a esta Campaña
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showPrices">
      <v-tabs
        class="ma-4"
        v-model="tab"
        background-color="white"
        color="primary"
        dark
      >
        <v-tab class="text--secondary">Por especie</v-tab>
        <v-tab class="text--secondary">Por Actividad</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <span
            class="ps-9 font-weight-normal font-italic text--secondary caption text--muted"
            >Al cambiar el precio de una especie, todas las actividades
            asociadas cambiaran su precio</span
          >
          <div v-for="specie in speciesWithPrices" :key="specie.id">
            <span
              class="warning--text ps-10"
              style="font-size: 15px"
              v-if="!specie.hasUniquePrice"
              >¡Hay actividades relacionadas a {{ specie.name }} que poseen
              diferentes precios!</span
            >
            <AbstractCampaignActivityPrice
              ref="speciesPriceComponents"
              class="ps-10"
              :priceObject="specie"
              :specieActivityPrices="
                activityPricesFromCampaignBySpecie[specie.id].activityPrices
              "
              :bySpecie="true"
              @rowSave="rowSave"
            />
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-for="specie in speciesWithPrices" :key="specie.id">
            <span
              class="mt-3 ps-9 text-overline grey--text text-subtitle-2"
              style="font-size: 18px !important"
              >{{ specie.name }}</span
            >
            <AbstractCampaignActivityPrice
              ref="activityPriceComponents"
              class="ms-3 ps-10 pt-1"
              v-for="activityPrice in activityPricesFromCampaignBySpecie[
                specie.id
              ].activityPrices"
              :key="activityPrice.id"
              :specieActivityPrices="
                activityPricesFromCampaignBySpecie[specie.id].activityPrices
              "
              :priceObject="activityPrice"
              @rowSave="rowSave"
              :bySpecie="false"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <v-row v-if="showActions">
      <AbstractCampaignActions
        :campaignVersion="singleCampaign.version"
        :campaignName="singleAbstractCampaign.name"
        :engagements="campaignEngagementsValues"
        :establishments="establishments"
        :activitySlices="campaignActivitySlices"
        :regions="regions"
        :species="species"
        @rowSave="rowSave"
        @saveCampaignQuickCopy="saveCampaignQuickCopy"
        @deleteSingleCampaignVersion="deleteSingleCampaignVersion"
        @badCampaignVersion="badCampaignVersion"
        @badCampaignName="badCampaignName"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import InfoGrid from "../components/Project/InfoGrid.vue";
import AbstractCampaignDetails from "../components/Abstract/Campaign/AbstractCampaignDetails.vue";
import AbstractCampaignActions from "../components/Abstract/Campaign/AbstractCampaignActions.vue";
import AbstractDraftMetricDataTable from "../components/Abstract/AbstractDraftMetricDataTable.vue";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import { unPackAndProcessCashflowKeys } from "../utils";
import {
  postRequest,
  putRequest,
  deleteRequest,
  getRequest,
} from "../managrx/serverCall";
import AbstractCampaignNewEngagement from "../components/Abstract/Campaign/AbstractCampaignNewEngagement.vue";
import AbstractCampaignActivityPrice from "../components/Abstract/Campaign/AbstractCampaignActivityPrice.vue";
import { refreshToken } from "../managrx/AutAndAuthServices";
import {
  campaignLevelTotalHaToolTip,
  LOG_ACTION_TO_PARSED_LOG_ACTION_ESP,
} from "../managrx/constants.js";
import { makeCampaignInfoGrid } from "../managrx/services";
import { parseToLocaleString } from "../managrx/utils";

export default {
  name: "SingleCampaign",
  data() {
    return {
      showT1InfoGrid: false,
      showT2InfoGrid: false,
      showT3InfoGrid: false,
      cashflowSpeciesActiveFilter: null,
      cashflowRegionActiveFilter: null,
      speciesIdFilter: null,
      regionIdFilter: null,
      logActionToEsp: LOG_ACTION_TO_PARSED_LOG_ACTION_ESP,
      tab: 0,
      loadedPages: [],
      logsLoading: false,
      page: 1,
      logs: {},
      logsToParse: [],
      totalPages: 0,
      logLimit: 15,
      totalLogs: 0,
      availableRegions: [],
      selectedRegions: [],
      makeActivityGroup: false,
      search: null,
      changeUnitsButtonTagT1: "usd",
      isUsdTotalMetricT1: false,
      metricUnitsT1: "usd/ha",
      changeUnitsButtonTagT3: "usd",
      isUsdTotalMetricT3: false,
      metricUnitsT3: "usd/ha",
      showCampaignDetail: false,
      showCampaignHistory: false,
      showMetrics: false,
      showPrices: false,
      showCashflows: false,
      showEngagements: true,
      showActions: false,
      showNewEngagement: false,
      resourceUtilizationFilter: localStorage.getItem("actualFilter") || "all",
      resourceUtilizationFilterItems: [
        { text: "Todo", value: "all" },
        { text: "Realizado", value: "actuals" },
        { text: "No Realizado", value: "forecast" },
      ],
      activityIdsFilter: [],
      shouldShowResourceUtilization: false,
      resourceUtilizationObserver: null,
    };
  },
  methods: {
    refreshLogs() {
      this.loadedPages = [];
      this.page = 1;
      this.logs = {};
      this.logsToParse = [];
      this.totalPages = 0;
      this.totalLogs = 0;
      this.getLogsLength();
      this.getPage();
    },
    async getPage() {
      if (this.loadedPages.includes(this.page)) {
        this.logsToParse = this.logs[this.page];
      } else {
        await this.getNextLogBatch();
        this.logsToParse = this.logs[this.page];
        this.loadedPages.push(this.page);
      }
    },
    async getLogsLength() {
      let result = await getRequest(
        `/module/get/audit-logs/campaign/length/${this.$route.params?.id2}`
      );
      if (result.success) {
        this.totalLogs = result.data?.numLogs;
        this.totalPages = Math.floor(this.totalLogs / this.logLimit);
      }
    },
    async getNextLogBatch() {
      this.logsLoading = true;
      let skip = (this.page - 1) * this.logLimit;
      let result = await getRequest(
        `/module/get/audit-logs/campaign/${this.$route.params?.id2}?limit=10&skip=${skip}`
      );
      if (result.success) {
        this.logs[this.page] = result.data?.logs;
      }
      this.logsLoading = false;
    },
    badCampaignVersion() {
      let notif = {
        success: false,
        data: {
          text: "La versión de campaña no es correcta",
          type: "alert",
        },
      };
      this.$store.dispatch("setNewNotification", notif);
    },
    badCampaignName() {
      let notif = {
        success: false,
        data: {
          text: "El nombre de campaña ingresado no es correcto",
          type: "alert",
        },
      };
      this.$store.dispatch("setNewNotification", notif);
    },
    async deleteSingleCampaignVersion() {
      let campaignId = this.$route.params.id2;
      let response = await deleteRequest(
        `/module/delete/campaign_version/${campaignId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.exitRoute();
      }
    },
    exitRoute() {
      this.$router.push({
        path: "/abstract-campaign/" + this.$route.params.id1,
      });
    },
    async putCampaign(payload) {
      let result = await putRequest(
        "/campaign/" + this.$route.params.id2,
        payload
      );
      if (!result.success) {
        this.$store.dispatch("setNewNotification", result);
      } else {
        this.$router.go();
      }
    },
    async putAbstractCampaign(payload) {
      let result = await putRequest(
        "/abstract_campaign/" + this.$route.params.id1,
        payload
      );
      if (!result.success) {
        this.$store.dispatch("setNewNotification", result);
      } else {
        this.$router.go();
      }
    },
    async saveCampaignQuickCopy() {
      this.isLoading = true;
      let abstractCampaignId = this.$route.params.id1;
      let campaignId = this.$route.params.id2;
      let campaignVersion = this.singleCampaign.version + 1; // Add 1 to increment Campaign Version
      let response = await postRequest(
        `/module/post/copy/campaign/${abstractCampaignId}/${campaignId}/${campaignVersion}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        let data = response?.data;
        if (data?.success) {
          this.$router.push({
            name: "SingleCampaign",
            params: { id1: this.$route.params.id1, id2: data?.campaignId },
          });
        } else {
          this.$store.dispatch("setNewNotification", {
            success: false,
            data: {
              type: "alert",
              text: "El Attachment no pudo llevarse a cabo.",
            },
          });
        }
      }
      this.$store.dispatch("getCampaignEngagements", this.selectedCampaign);
      this.$store.dispatch("getEstablishments");
    },
    async getCampaignCashflowFiltered() {
      this.cashflowRegionActiveFilter = this.regionIdFilter;
      this.cashflowSpeciesActiveFilter = this.speciesIdFilter;
      this.cashflowActivityIdsActiveFilter = this.activityIdsFilter?.join(",") || "";

      await Promise.all([
        this.$store.dispatch("getCampaignCashflow", {
          startYear: this.cashflowStartYear,
          endYear: this.cashflowEndYear,
          startMonth: this.cashflowStartMonth,
          endMonth: this.cashflowEndMonth,
          campaignId: this.campaignId,
          regionId: this.regionIdFilter,
          speciesId: this.speciesIdFilter,
          activityIds: this.activityIdsFilter?.length ? this.activityIdsFilter.join(",") : null,
        }),
        this.$store.dispatch("updateCampaignResourceUtilization", {
          campaignId: this.campaignId,
          speciesId: this.speciesIdFilter,
          regionId: this.regionIdFilter,
          activityIds: this.activityIdsFilter?.length ? this.activityIdsFilter.join(",") : null,
        }),
      ]);
    },
    async getCampaignMetricsFiltered() {
      this.$store.dispatch("setPageLoadingStatus", true);
      await this.$store.dispatch("getCampaignMetrics", {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
        speciesId: this.speciesIdFilter,
        regionId: this.regionIdFilter,
        activityIds: this.activityIdsFilter?.length
          ? this.activityIdsFilter.join(",")
          : null,
      });
      this.cashflowSpeciesActiveFilter = this.speciesIdFilter;
      this.cashflowRegionActiveFilter = this.regionIdFilter;
      this.cashflowActivityIdsActiveFilter =
        this.activityIdsFilter?.join(",") || "";
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async rowSave() {
      this.$store.dispatch("getCampaignCashflow", {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
      });
      this.$store.dispatch("getSpecies");

      await this.$store.dispatch(
        "getActivityPricesFromCampaign",
        this.$route.params.id2
      );
      this.$store.dispatch("getCampaignActivities", {
        filterKey: "campaign_id",
        filterValue: this.$route.params.id2,
      });
      this.$store.dispatch("getCampaignMetrics", {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
      });

      this.refreshPrices();
    },
    refreshPrices() {
      this.$refs?.speciesPriceComponents?.forEach((component) =>
        component.resetNewRow()
      );
      this.$refs.activityPriceComponents?.forEach((component) =>
        component.resetNewRow()
      );
    },
    async saveNewEngagement(payload) {
      try {
        let response = await postRequest("/engagement", payload);
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          let tokenResponse = await refreshToken();
          if (tokenResponse.success) {
            let id = response.data;
            this.$router.push("/engagement/" + id);
          } else {
            let notif = {
              success: false,
              data: {
                type: "alert",
                text: "Error al refrecar el Token, porfavor sal e inicia sesión nuevamente",
              },
            };
            this.$store.dispatch("setNewNotification", notif);
          }
        }
      } catch (error) {
        console.log(error);
        let notif = { type: "error", text: `${error?.response?.data?.error}` };
        this.$store.dispatch("setNewNotification", notif);
      }

      this.$store.dispatch("getCampaignEngagements", this.selectedCampaign);
      this.$store.dispatch("getEstablishments");
    },
    setMetricUnits(table) {
      if (table == "t1") {
        if (this.isUsdTotalMetricT1) {
          this.metricUnitsT1 = "usd/ha";
          this.changeUnitsButtonTagT1 = "usd";
          this.isUsdTotalMetricT1 = false;
        } else {
          this.metricUnitsT1 = "usd";
          this.changeUnitsButtonTagT1 = "usd/ha";
          this.isUsdTotalMetricT1 = true;
        }
      } else if (table == "t3") {
        if (this.isUsdTotalMetricT3) {
          this.metricUnitsT3 = "usd/ha";
          this.changeUnitsButtonTagT3 = "usd";
          this.isUsdTotalMetricT3 = false;
        } else {
          this.metricUnitsT3 = "usd";
          this.changeUnitsButtonTagT3 = "usd/ha";
          this.isUsdTotalMetricT3 = true;
        }
      }
    },

    async showCampaignSection(selectedSection) {
      let sections = {
        showNewEngagement: false,
        showCampaignDetail: false,
        showCampaignHistory: false,
        showPrices: false,
        showMetrics: false,
        showEngagements: false,
        showActions: false,
        showCashflows: false,
      };
      sections[selectedSection] = true;
      this.showNewEngagement = sections.showNewEngagement;
      this.showCampaignDetail = sections.showCampaignDetail;
      this.showCampaignHistory = sections.showCampaignHistory;
      this.showPrices = sections.showPrices;
      this.showMetrics = sections.showMetrics;
      this.showCashflows = sections.showCashflows;
      this.showEngagements = sections.showEngagements;
      this.showActions = sections.showActions;

      await this.$store.dispatch("clearCampaignMetricsAndCashflow");

      // Create a common payload for both metrics and cashflow
      const commonPayload = {
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
        campaignId: this.$route.params.id2,
        speciesId: this.speciesIdFilter,
        regionId: this.regionIdFilter,
        activityIds: this.activityIdsFilter?.length
          ? this.activityIdsFilter.join(",")
          : null,
      };

      if (selectedSection === "showMetrics") {
        this.$store.dispatch("setPageLoadingStatus", true);
        try {
          await this.$store.dispatch("getCampaignMetrics", commonPayload);
        } finally {
          this.$store.dispatch("setPageLoadingStatus", false);
        }
      }

      if (selectedSection === "showCashflows" && !this.isCampaignActivitiesEmpty) {
        this.$store.dispatch("setPageLoadingStatus", true);
        try {
          await this.$store.dispatch("getCampaignCashflow", commonPayload);
          
          this.$nextTick(() => {
            this.initResourceUtilizationObserver();
          });
        } finally {
          this.$store.dispatch("setPageLoadingStatus", false);
        }
      }
    },
    async handleResourceUtilizationFilterChange(value) {
      localStorage.setItem("actualFilter", value);
      if (this.shouldShowResourceUtilization) {
        try {
          await this.$store.dispatch("updateCampaignResourceUtilization", {
            campaignId: this.campaignId,
            speciesId: this.speciesIdFilter,
            regionId: this.regionIdFilter,
            activityIds: this.activityIdsFilter?.length
              ? this.activityIdsFilter.join(",")
              : null,
          });
        } catch (error) {
          console.error("Error updating resource utilization:", error);
        }
      }
    },
    initResourceUtilizationObserver() {
      this.resourceUtilizationObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            this.loadResourceUtilization();
            this.resourceUtilizationObserver.disconnect();
          }
        },
        {
          threshold: 0.1
        }
      );

      if (this.$refs.resourceUtilizationSection) {
        this.resourceUtilizationObserver.observe(this.$refs.resourceUtilizationSection);
      }
    },
    async loadResourceUtilization() {
      if (!this.shouldShowResourceUtilization) {
        this.shouldShowResourceUtilization = true;
        try {
          await this.$store.dispatch("updateCampaignResourceUtilization", {
            campaignId: this.campaignId,
            speciesId: this.speciesIdFilter,
            regionId: this.regionIdFilter,
            activityIds: this.activityIdsFilter?.length
              ? this.activityIdsFilter.join(",")
              : null,
          });
        } catch (error) {
          console.error("Error loading resource utilization:", error);
          this.shouldShowResourceUtilization = false;
        }
      }
    },
    getRegionName(id) {
      return this.regions.find((r) => r.id === id)?.abbreviation || "";
    },
    getSpeciesName(id) {
      return this.species.find((s) => s.id === id)?.name || "";
    },
    getActivityName(id) {
      return this.activities.find((a) => a.id === id)?.name || "";
    },
    removeActivity(id) {
      this.activityIdsFilter = this.activityIdsFilter.filter(
        (actId) => actId !== id
      );
    },
  },
  computed: {
    ...mapGetters([
      "singleCampaign",
      "singleAbstractCampaign",
      "regions",
      "species",
      "activities",
      "campaignActivities",
      "campaignActivitySlices",
      "campaignEngagements",
      "establishments",
      "activityPricesFromCampaign",
      "isPageLoading",
      "campaignCashflow",
      "campaignResourceUtilization",
      "campaignMetrics",
    ]),
    t1InfoGrid() {
      return makeCampaignInfoGrid(this.campaignMetrics?.t1);
    },
    speciesIdFilterOptions() {
      let availableSpeciesId = this.campaignActivitySlices.map((el) =>
        parseInt(el?.speciesId)
      );

      return this.species.filter((el) => availableSpeciesId.includes(el?.id));
    },
    isCashflowFiltered() {
      const hasValidRegion =
        this.regionIdFilter &&
        this.availableRegionOptions.some((r) => r.id === this.regionIdFilter);
      const hasValidSpecies =
        this.speciesIdFilter &&
        this.availableSpeciesOptions.some((s) => s.id === this.speciesIdFilter);
      const hasValidActivities =
        this.activityIdsFilter?.length &&
        this.activityIdsFilter.every((id) =>
          this.availableActivityOptions.some((a) => a.id === id)
        );

      const currentFilters = {
        region: this.regionIdFilter,
        species: this.speciesIdFilter,
        activities: this.activityIdsFilter?.join(",") || "",
      };

      const activeFilters = {
        region: this.cashflowRegionActiveFilter,
        species: this.cashflowSpeciesActiveFilter,
        activities: this.cashflowActivityIdsActiveFilter,
      };

      if (!hasValidRegion || !hasValidSpecies || !hasValidActivities) {
        return false;
      }

      return JSON.stringify(currentFilters) === JSON.stringify(activeFilters);
    },
    selectableCampaignEngagementsValues() {
      return this.campaignEngagementsValues.filter((el) =>
        this.selectedRegions.includes(el.region)
      );
    },
    speciesWithPrices() {
      return this.species.map((el) => {
        return {
          ...el,
          hasUniquePrice:
            new Set(this.activityPricesFromCampaignBySpecie?.[el?.id]?.prices)
              ?.size == 1
              ? true
              : false,
          price:
            new Set(this.activityPricesFromCampaignBySpecie?.[el?.id]?.prices)
              ?.size == 1
              ? this.activityPricesFromCampaignBySpecie?.[el?.id]?.prices?.[0]
              : null,
        };
      });
    },
    parsedLogs() {
      return this.logsToParse?.map((el) => {
        return {
          ...el,
          logDate: new Date(el?.logDatetime)
            ?.toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
          logTime: new Date(el?.logDatetime)
            ?.toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[1],
          logAction: this.logActionToEsp[el?.logAction],
          logTablename: el?.logOriginTablename,
        };
      });
    },
    activityPricesFromCampaignBySpecie() {
      let result = {};
      for (const specie of this.species) {
        result[specie.id] = {
          activityPrices: this.activityPricesFromCampaign.filter(
            (el) => el.speciesId == specie.id
          ),
          prices: this.activityPricesFromCampaign
            .filter((el) => el.speciesId == specie.id)
            .map((el) => el.price),
        };
      }
      return result;
    },
    campaignTotalHaToolTip() {
      return campaignLevelTotalHaToolTip;
    },
    campaignStartDate() {
      return this.singleAbstractCampaign?.startDate;
    },
    campaignEndDate() {
      return this.singleAbstractCampaign?.endDate;
    },
    cashflowEndMonth() {
      let date = new Date(this.campaignEndDate);

      return date.getMonth() + 1;
    },
    cashflowStartMonth() {
      let date = new Date(this.campaignStartDate);

      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date = new Date(this.campaignEndDate);

      return date.getFullYear();
    },
    cashflowStartYear() {
      let date = new Date(this.campaignStartDate);

      return date.getFullYear();
    },
    isCampaignActivitiesEmpty() {
      return this.campaignActivities.length === 0;
    },
    campaignCashflowColumns() {
      return unPackAndProcessCashflowKeys(this.campaignCashflow?.keys);
    },
    campaingCashflowRows() {
      return this.campaignCashflow?.rows;
    },
    campaingResourceUtilizationColumns() {
      return unPackAndProcessCashflowKeys(
        this.campaignResourceUtilization?.keys
      ) || [];
    },
    campaignResourceUtilizationRows() {
      return this.campaignResourceUtilization?.rows || [];
    },
    campaignId() {
      return parseInt(this.$route.params.id2);
    },
    filteredEstablishment() {
      let inUseEngagementsId = this.campaignEngagements.items.map(
        (el) => el.establishmentName
      );

      return this.establishments.filter(
        (el) => !inUseEngagementsId.includes(el.name)
      );
    },
    campaignKpi() {
      if (!this.campaignMetrics?.kpi) {
        return [];
      } else {
        let kpis = this.campaignMetrics?.kpi;

        return makeCampaignInfoGrid(kpis);
      }
    },
    t1MetricsToLocalString() {
      if (!this.campaignMetrics?.t1) {
        return [];
      }
      let t1Metrics = this.campaignMetrics?.t1;
      t1Metrics = t1Metrics.map((element) => {
        return {
          ...element,
          value: element.value.toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          }),
          value_ha:
            element.unit != "%"
              ? element["value_ha"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : null,
        };
      });
      return t1Metrics;
    },
    t1MetricsInfoGrid() {
      let t1MetricsValues = this.t1MetricsToLocalString;
      if (!t1MetricsValues) {
        return [];
      }
      let t1Metrics;
      if (this.isUsdTotalMetricT1) {
        t1Metrics = t1MetricsValues;
      } else {
        t1Metrics = t1MetricsValues.map((element) => {
          return {
            value:
              element.unit == "%" ? element?.["value"] : element?.["value_ha"],
            unit:
              element.unit == "%" ? element?.["unit"] : element?.["unit_ha"],
            key: element?.["key"],
          };
        });
      }
      return makeCampaignInfoGrid(t1Metrics);
    },
    t1Metrics() {
      let t1MetricsValues = this.t1MetricsToLocalString;
      if (!t1MetricsValues) {
        return [];
      }
      let t1Metrics;
      if (this.isUsdTotalMetricT1) {
        t1Metrics = t1MetricsValues;
      } else {
        t1Metrics = t1MetricsValues.map((element) => {
          return {
            value:
              element.unit == "%" ? element?.["value"] : element?.["value_ha"],
            unit:
              element.unit == "%" ? element?.["unit"] : element?.["unit_ha"],
            key: element?.["key"],
          };
        });
      }
      return t1Metrics;
    },
    t2Metrics() {
      return this.campaignMetrics?.t2;
    },
    t2Values() {
      return this.campaignMetrics?.t2.map((el) => {
        return makeCampaignInfoGrid(
          [
            {
              unit: "usd/ha",
              value: el?.["forward_price"],
              key: "Precio",
            },
            {
              unit: "qq/ha",
              value: el?.["dynamic_yield"],
              key: "Rinde",
            },
          ],
          0
        )[0];
      });
    },
    t2SubTitles() {
      return this.campaignMetrics?.t2.map((el) => el?.["activity_name"]);
    },
    t3SubTitles() {
      if (!Array.isArray(this.campaignMetrics?.t3) || !this.campaignMetrics?.t3[0]) {
        return [];
      }
      return this.campaignMetrics.t3[0].map(el => el.activity_name || '');
    },
    t3Metrics() {
      // First ensure we have data and it's an array
      if (!Array.isArray(this.campaignMetrics?.t3) || !this.campaignMetrics?.t3.length) {
        return [];
      }

      // Get the first element which contains the activities array
      let t3MetricsData = this.campaignMetrics.t3[0];
      
      // Ensure t3MetricsData is an array before mapping
      if (!Array.isArray(t3MetricsData)) {
        return [];
      }

      return t3MetricsData.map((el) => ({
        ...el,
        // Ensure all required fields exist and are properly formatted
        activity_name: el.activity_name || '',
        assigned_hectares: parseToLocaleString(el.assigned_hectares),
        total_produced_tons: parseToLocaleString(el.total_produced_tons),
        profits_by_hectare: parseToLocaleString(el.profits_by_hectare),
        total_profits: parseToLocaleString(el.total_profits),
        total_costs_by_hectare: parseToLocaleString(el.total_costs_by_hectare),
        total_costs: parseToLocaleString(el.total_costs),
        direct_costs_by_hectare: parseToLocaleString(el.direct_costs_by_hectare),
        total_direct_costs: parseToLocaleString(el.total_direct_costs),
        autofinanciable_costs_by_hectare: parseToLocaleString(el.autofinanciable_costs_by_hectare),
        total_autofinanciable_costs: parseToLocaleString(el.total_autofinanciable_costs),
        nonautofinanciable_costs_by_hectare: parseToLocaleString(el.nonautofinanciable_costs_by_hectare),
        total_nonautofinanciable_costs: parseToLocaleString(el.total_nonautofinanciable_costs),
        iva_net_by_hectares: parseToLocaleString(el.iva_net_by_hectares),
        total_iva_net: parseToLocaleString(el.total_iva_net),
        net_income_over_expenses_percent: parseToLocaleString(el.net_income_over_expenses_percent)
      }));
    },
    t3MetricsInfoGrid() {
      if (!Array.isArray(this.campaignMetrics?.t3) || !this.campaignMetrics?.t3[0]) {
        return [];
      }

      return this.campaignMetrics.t3[0].map((el) => {
        return makeCampaignInfoGrid([
          {
            key: "Hectáreas Asignadas",
            value: el.assigned_hectares,
            unit: "ha",
          },
          {
            key: "Margen",
            value: !this.isUsdTotalMetricT3
              ? el.profits_by_hectare
              : el.total_profits,
            unit: this.metricUnitsT3,
          },
          {
            key: "Costo Total",
            value: !this.isUsdTotalMetricT3
              ? el.total_costs_by_hectare
              : el.total_costs,
            unit: this.metricUnitsT3,
          },
          {
            key: "Costo Directo",
            value: !this.isUsdTotalMetricT3
              ? el.direct_costs_by_hectare
              : el.total_direct_costs,
            unit: this.metricUnitsT3,
          },
          {
            key: "Autofinanciables",
            value: !this.isUsdTotalMetricT3
              ? el.autofinanciable_costs_by_hectare
              : el.total_autofinanciable_costs,
            unit: this.metricUnitsT3,
          },
          {
            key: "No Autofinanciables",
            value: !this.isUsdTotalMetricT3
              ? el.nonautofinanciable_costs_by_hectare
              : el.total_nonautofinanciable_costs,
            unit: this.metricUnitsT3,
          },
          {
            key: "Iva Neto",
            value: !this.isUsdTotalMetricT3
              ? el.iva_net_by_hectares
              : el.total_iva_net,
            unit: this.metricUnitsT3,
          },
          {
            key: "Ingreso Neto/Gasto",
            value: el.net_income_over_expenses_percent,
            unit: "%",
          },
        ])[0];
      });
    },
    t4Metrics() {
      let t4Metrics = this.campaignMetrics?.t4;
      if (!t4Metrics) {
        return [];
      }
      t4Metrics.forEach((element) => {
        element.value = element.value.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.value_ha = element.value_ha.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });

      return t4Metrics;
    },
    t5Metrics() {
      return (
        this.campaignMetrics?.t5?.map((t5) => {
          return {
            ...t5,
            date: new Date(t5.date)
              // Date should be dd/mm/yyyy
              .toLocaleString("es-AR", {
                timeZone: "America/Argentina/Buenos_Aires",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .split(",")[0],
          };
        }) || []
      );
    },
    t6Metrics() {
      return (this.campaignMetrics?.t6 || [])
        .filter((el) => el?.["activity_name"] != null)
        .map((el) => {
          return {
            ...el,
            ["tons_handed_over"]: el["tons_handed_over"]
              ? el["tons_handed_over"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
            ["bonus_tons"]: el["bonus_tons"]
              ? el["bonus_tons"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
            ["variable_sharecropping_costs"]: el["variable_sharecropping_costs"]
              ? el["variable_sharecropping_costs"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
            ["fixed_sharecropping_costs"]: el["fixed_sharecropping_costs"]
              ? el["fixed_sharecropping_costs"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
            ["bonus_sharecropping_costs"]: el["bonus_sharecropping_costs"]
              ? el["bonus_sharecropping_costs"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
            ["sharecropping_usd_ha"]: el["sharecropping_usd_ha"]
              ? el["sharecropping_usd_ha"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
          };
        });
    },
    t7Metrics() {
      return (this.campaignMetrics?.t7 || [])
        .filter((el) => el["activity_name"] != null)
        .map((el) => {
          return {
            ...el,
            ["item_cost"]: el["item_cost"]
              ? el["item_cost"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
            ["item_cost_percent"]: el["item_cost_percent"]
              ? el["item_cost_percent"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : 0,
          };
        });
    },
    t8Metrics() {
      return (this.campaignMetrics?.t8 || []).filter(
        (el) => el["activity_name"] != null
      );
    },

    tHeaders() {
      return {
        t1Headers: [
          { text: "Indicator", value: "key", class: "secondary white--text" },
          { text: "Value", value: "value", class: "secondary white--text" },
          { text: "Unit", value: "unit", class: "secondary white--text" },
        ],
        t2Headers: [
          {
            text: "Actividad",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "Rinde",
            value: "dynamic_yield",
            class: "secondary white--text",
          },
          {
            text: "Precio",
            value: "forward_price",
            class: "secondary white--text",
          },
        ],
        t4Headers: [
          { text: "Metric", value: "key", class: "secondary white--text" },
          { text: "usd/Ha", value: "value_ha", class: "secondary white--text" },
          { text: "usd Total", value: "value", class: "secondary white--text" },
        ],
        t3Headers: [
          {
            text: "Activity",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "Hectáreas Asignadas",
            value: "assigned_hectares",
            class: "secondary white--text",
          },
          {
            text: "Producción Total (tn)",
            value: "total_produced_tons",
            class: "secondary white--text",
          },
          {
            text: "Margen " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "profits_by_hectare"
              : "total_profits",
            class: "secondary white--text",
          },
          {
            text: "Costo Total " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "total_costs_by_hectare"
              : "total_costs",
            class: "secondary white--text",
          },
          {
            text: "Costo Directo " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "direct_costs_by_hectare"
              : "total_direct_costs",
            class: "secondary white--text",
          },
          {
            text: "Autofinanciables " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "autofinanciable_costs_by_hectare"
              : "total_autofinanciable_costs",
            class: "secondary white--text",
          },
          {
            text: "No Autofinanciables " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "nonautofinanciable_costs_by_hectare"
              : "total_nonautofinanciable_costs",
            class: "secondary white--text",
          },
          {
            text: "Iva Neto " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "iva_net_by_hectares"
              : "total_iva_net",
            class: "secondary white--text",
          },
          {
            text: "Ingreso Neto/Gasto %",
            value: "net_income_over_expenses_percent",
            class: "secondary white--text",
          },
        ],
        t5Headers: [
          { text: "Fecha", value: "date", class: "secondary white--text" },
          {
            text: "Cantidad qq/ha",
            value: "qq_ha",
            class: "secondary white--text",
          },
          {
            text: "Precio",
            value: "rent_qq_activity_price",
            class: "secondary white--text",
          },
          {
            text: "Costo usd/ha",
            value: "installment_cost",
            class: "secondary white--text",
          },
          {
            text: "Cuota %",
            value: "installment_percent",
            class: "secondary white--text",
          },
        ],
        t6Headers: [
          {
            text: "Activity",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "Aparcerías (tn)",
            value: "tons_handed_over",
            class: "secondary white--text",
            unit: "tn",
          },
          {
            text: "Bonificacion Alquiler (tn)",
            class: "secondary white--text",
            value: "bonus_tons",
            unit: "tn",
          },
          {
            text: "Aparcería Variable",
            value: "variable_sharecropping_costs",
            class: "secondary white--text",
          },
          {
            text: "Aparcería Fija",
            value: "fixed_sharecropping_costs",
            class: "secondary white--text",
          },
          {
            text: "Aparcería Bonificada",
            value: "bonus_sharecropping_costs",
            class: "secondary white--text",
          },
          {
            text: "Aparcería usd/ha",
            value: "sharecropping_usd_ha",
            class: "secondary white--text",
          },
        ],
        t7Headers: [
          {
            text: "Establecimiento",
            value: "establishment_name",
            class: "secondary white--text",
          },
          {
            text: "Actividad",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "Arrendamiento USD",
            value: "item_cost",
            class: "secondary white--text",
          },
          {
            text: "Arrendamiento %",
            value: "item_cost_percent",
            class: "secondary white--text",
          },
          {
            text: "% App",
            value: "rent_percent_application",
            class: "secondary white--text",
          },
        ],
        t8Headers: [
          {
            text: "Actividad",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "% Realizado",
            value: "actual_pct",
            class: "secondary white--text",
          },
          {
            text: "% No realizado",
            value: "scrapped_pct",
            class: "secondary white--text",
          },
          {
            text: "% Proyectado",
            value: "planned_pct",
            class: "secondary white--text",
          },
        ],
      };
    },
    processedSingleCampaign() {
      return {
        id: this.singleCampaign.id,
        version: this.singleCampaign.version,
        campaignVersionName: this.singleCampaign.campaignVersionName,
        name: this.singleAbstractCampaign.name,
        processedName: this.singleAbstractCampaign?.name
          ? this.singleAbstractCampaign?.name +
            " v" +
            this.singleCampaign?.version
          : "Cargando Campaña...",
        endDate: this.singleAbstractCampaign.endDate?.slice(0, 10),
        startDate: this.singleAbstractCampaign.startDate?.slice(0, 10),
      };
    },
    campaignEngagementsValues() {
      if (this.campaignEngagements?.items.length) {
        if (this.search) {
          let search = this.search.toLowerCase();
          return this.campaignEngagements?.items.filter((el) =>
            el.establishmentName.toLowerCase().includes(search)
          );
        }
        return this.campaignEngagements?.items;
      } else {
        return [];
      }
    },
    establishmentName() {
      return this.singleEstablishment?.name;
    },
    hasActiveFilters() {
      return (
        this.regionIdFilter ||
        this.speciesIdFilter ||
        this.activityIdsFilter?.length
      );
    },
    availableRegionOptions() {
      if (!this.campaignActivitySlices?.length || !this.regions?.length)
        return this.regions || [];

      let filteredSlices = this.campaignActivitySlices;

      if (this.speciesIdFilter) {
        filteredSlices = filteredSlices.filter(
          (slice) => Number(slice.speciesId) === this.speciesIdFilter
        );
      }

      if (this.activityIdsFilter?.length) {
        filteredSlices = filteredSlices.filter((slice) =>
          this.activityIdsFilter.includes(Number(slice.activityId))
        );
      }

      const uniqueRegions = [
        ...new Set(filteredSlices.map((slice) => Number(slice.regionId))),
      ];

      return this.regions.filter((region) => uniqueRegions.includes(region.id));
    },

    availableSpeciesOptions() {
      if (!this.campaignActivitySlices?.length || !this.species?.length)
        return this.species || [];

      let filteredSlices = this.campaignActivitySlices;

      if (this.regionIdFilter) {
        filteredSlices = filteredSlices.filter(
          (slice) => Number(slice.regionId) === this.regionIdFilter
        );
      }

      if (this.activityIdsFilter?.length) {
        filteredSlices = filteredSlices.filter((slice) =>
          this.activityIdsFilter.includes(Number(slice.activityId))
        );
      }

      const uniqueSpecies = [
        ...new Set(filteredSlices.map((slice) => Number(slice.speciesId))),
      ];

      return this.species.filter((species) =>
        uniqueSpecies.includes(species.id)
      );
    },

    availableActivityOptions() {
      if (!this.campaignActivitySlices?.length || !this.activities?.length)
        return this.activities || [];

      let filteredSlices = this.campaignActivitySlices;

      if (this.regionIdFilter) {
        filteredSlices = filteredSlices.filter(
          (slice) => Number(slice.regionId) === this.regionIdFilter
        );
      }

      if (this.speciesIdFilter) {
        filteredSlices = filteredSlices.filter(
          (slice) => Number(slice.speciesId) === this.speciesIdFilter
        );
      }

      const uniqueActivities = [
        ...new Set(filteredSlices.map((slice) => Number(slice.activityId))),
      ];

      return this.activities.filter((activity) =>
        uniqueActivities.includes(activity.id)
      );
    },
    hasResourceUtilizationData() {
      return this.campaingResourceUtilizationColumns?.length > 0;
    },
    resourceUtilizationLoadingMessage() {
      if (!this.shouldShowResourceUtilization) {
        return "Desplácese hacia abajo para cargar los datos de utilización de recursos";
      }
      return "Sin recursos para mostrar todavía.";
    },
    activeFilters() {
      return {
        speciesId: this.speciesIdFilter,
        regionId: this.regionIdFilter,
        activityIds: this.activityIdsFilter?.length
          ? this.activityIdsFilter.join(",")
          : null,
      };
    },
  },
  watch: {
    speciesIdFilter(newVal) {
      if (newVal) {
        this.activityIdsFilter = [];
      }
    },
    activityIdsFilter(newVal) {
      if (newVal.length) {
        this.speciesIdFilter = null;
      }
    },
    availableActivityOptions: {
      handler(newOptions) {
        if (this.activityIdsFilter.length) {
          this.activityIdsFilter = this.activityIdsFilter.filter((id) =>
            newOptions.some((option) => option.id === id)
          );
        }
      },
      deep: true,
    },
    activeFilters: {
      handler: async function(newFilters) {
        // Only refetch if we're on metrics or cashflow tabs
        if (this.showMetrics || this.showCashflows) {
          const payload = {
            startYear: this.cashflowStartYear,
            startMonth: this.cashflowStartMonth,
            endYear: this.cashflowEndYear,
            endMonth: this.cashflowEndMonth,
            campaignId: this.$route.params.id2,
            ...newFilters
          };

          if (this.showMetrics) {
            await this.$store.dispatch("getCampaignMetrics", payload);
          }
          if (this.showCashflows) {
            await this.$store.dispatch("getCampaignCashflow", payload);
          }
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch("getSingleCampaign", this.$route.params.id2);
    this.$store.dispatch("getCampaignEngagements", this.$route.params.id2);
    this.$store.dispatch("getAbstractCampaigns");
    this.$store.dispatch("getCampaigns");
    this.$store.dispatch("getSpecies");
    this.$store.dispatch("getActivities");
    this.$store.dispatch("getEstablishments");
    this.$store.dispatch(
      "getActivityPricesFromCampaign",
      this.$route.params.id2
    );

    await Promise.all([
      this.$store.dispatch("getSingleAbstractCampaign", this.$route.params.id1),
      this.$store.dispatch("getCampaignActivities", {
        filterKey: "campaign_id",
        filterValue: this.$route.params.id2,
      }),
    ]);
    document.title = this.processedSingleCampaign?.processedName;

    for (const campaignEngagement of this.campaignEngagementsValues) {
      let region = campaignEngagement.region;
      if (!this.availableRegions.includes(region)) {
        this.availableRegions.push(region);
      }
    }
    this.selectedRegions = this.availableRegions;
  },
  components: {
    AbstractCampaignDetails,
    AbstractCampaignActions,
    AbstractDraftMetricDataTable,
    DenseCashflowTable,
    AbstractCampaignNewEngagement,
    AbstractCampaignActivityPrice,
    InfoGrid,
  },
  beforeDestroy() {
    if (this.resourceUtilizationObserver) {
      this.resourceUtilizationObserver.disconnect();
    }
  },
};
</script>

<style scoped>
.filter-field ::v-deep .v-select__selection {
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.resource-utilization-header {
  transition: opacity 0.3s ease;
}

.resource-utilization-header.is-muted {
  opacity: 0.5;
  pointer-events: none;
}

.resource-utilization-header h2 {
  transition: color 0.3s ease;
}

.resource-utilization-content {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
