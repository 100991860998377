<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- Campaign Version Name Section -->
        <v-card class="mb-6" outlined>
          <v-card-title class="text-subtitle-1 grey lighten-3">
            <v-icon left color="primary">mdi-tag-text</v-icon>
            Nombre de Versión
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row align="center">
              <v-col cols="12" md="9">
                <v-text-field
                  v-model="campaignVersionName"
                  label="Nombre descriptivo de la versión"
                  outlined
                  dense
                  hide-details
                  placeholder="Ej: Pre-siembra 2024"
                  clearable
                  @keyup.enter="updateCampaignVersionName"
                  :disabled="versionNameSaving"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="d-flex justify-end">
                <v-btn
                  color="primary"
                  @click="updateCampaignVersionName"
                  :loading="versionNameSaving"
                  :disabled="!hasVersionNameChanged"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Campaign Details Section -->
        <v-card outlined>
          <v-card-title class="text-subtitle-1 grey lighten-3">
            <v-icon left color="primary">mdi-cog</v-icon>
            Configuración General
          </v-card-title>

          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="processedSingleCampaign.id"
                  label="ID"
                  outlined
                  dense
                  readonly
                  filled
                  prepend-icon="mdi-pound"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="version"
                  label="Versión"
                  outlined
                  dense
                  :readonly="!editMode"
                  :filled="!editMode"
                  prepend-icon="mdi-numeric"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Nombre de Campaña"
                  outlined
                  dense
                  :readonly="!editMode"
                  :filled="!editMode"
                  prepend-icon="mdi-format-title"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="startDate"
                  label="Fecha de inicio"
                  type="date"
                  outlined
                  dense
                  :readonly="!editMode"
                  :filled="!editMode"
                  prepend-icon="mdi-calendar-start"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="endDate"
                  label="Fecha de finalización"
                  type="date"
                  outlined
                  dense
                  :readonly="!editMode"
                  :filled="!editMode"
                  prepend-icon="mdi-calendar-end"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Action Buttons -->
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  v-if="!editMode"
                  color="warning"
                  class="mr-2"
                  @click="editClick"
                  outlined
                >
                  <v-icon left>mdi-pencil</v-icon>
                  Editar
                </v-btn>

                <v-btn
                  v-if="editMode"
                  color="grey"
                  class="mr-2"
                  @click="stopEditMode"
                  outlined
                >
                  <v-icon left>mdi-close</v-icon>
                  Cancelar
                </v-btn>

                <v-btn v-if="editMode" color="primary" @click="saveChanges">
                  <v-icon left>mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CampaignDetail",
  props: {
    processedSingleCampaign: Object,
  },
  computed: {
    hasVersionNameChanged() {
      return (
        this.campaignVersionName !==
        this.processedSingleCampaign?.campaignVersionName
      );
    },
  },
  methods: {
    editClick() {
      this.editMode = true;
    },
    stopEditMode() {
      this.editMode = false;
      this.endDate = this.processedSingleCampaign?.endDate;
      this.startDate = this.processedSingleCampaign?.startDate;
      this.name = this.processedSingleCampaign?.name;
      this.version = this.processedSingleCampaign?.version;
    },
    saveChanges() {
      let payloadCampaign = {};
      let payloadAbstractCampaign = {};

      if (this.startDate != this.processedSingleCampaign?.startDate) {
        payloadAbstractCampaign["startDate"] = new Date(
          this.startDate
        ).toISOString();
      }
      if (this.endDate != this.processedSingleCampaign?.endDate) {
        payloadAbstractCampaign["endDate"] = new Date(
          this.endDate
        ).toISOString();
      }
      if (this.name != this.processedSingleCampaign?.name) {
        payloadAbstractCampaign["name"] = this.name;
      }
      if (this.version != this.processedSingleCampaign?.version) {
        payloadCampaign["version"] = this.version;
      }
      if (Object.keys(payloadAbstractCampaign).length) {
        this.$emit("putAbstractCampaign", payloadAbstractCampaign);
      }
      if (Object.keys(payloadCampaign).length) {
        this.$emit("putCampaign", payloadCampaign);
      }
    },
    async updateCampaignVersionName() {
      if (!this.hasVersionNameChanged) return;

      this.versionNameSaving = true;
      try {
        await this.$emit("putCampaign", {
          id: this.processedSingleCampaign.id,
          campaignVersionName: this.campaignVersionName,
        });
      } finally {
        this.versionNameSaving = false;
      }
    },
  },
  data() {
    return {
      editMode: false,
      versionNameSaving: false,
      endDate: this.processedSingleCampaign?.endDate,
      startDate: this.processedSingleCampaign?.startDate,
      name: this.processedSingleCampaign?.name,
      version: this.processedSingleCampaign?.version,
      campaignVersionName: this.processedSingleCampaign?.campaignVersionName,
    };
  },
  watch: {
    processedSingleCampaign: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.campaignVersionName = newVal.campaignVersionName;
          this.endDate = newVal.endDate;
          this.startDate = newVal.startDate;
          this.name = newVal.name;
          this.version = newVal.version;
        }
      },
    },
  },
};
</script>

<style scoped>
.v-card {
  transition: all 0.3s ease;
}

.v-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}
</style>
